<app-header [back]="true"></app-header>

<div class="container">
    <div class="row mt-5">
        <div class="col-12">
            <button (click)="selectAll(true)" class="btn btn-primary text-white">
                <span>Tots</span>
            </button>
            <button (click)="selectAll(false)" class="btn btn-secondary text-white ms-2">
                <span>Cap</span>
            </button>
        </div>
    </div>
    <div class="row mt-3">
        <div  *ngFor="let category of categories"  class="col-12 col-lg-6">
            <div class="form-check form-switch">
                <input class="form-check-input" (change)="check(category)" [checked]="category.checked" type="checkbox" role="switch" id="category-{{category.id}}">
                <label class="form-check-label" for="category-{{category.nom}}">{{category.nom}}</label>
            </div>
        </div>
    </div>
</div>
