import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../../layout/header/header.component';
import { Activitat } from '../../models/Activitat';
import { Category } from '../../models/Category';
import { DbService } from '../../services/db.service';
import { ActivatedRoute } from '@angular/router';
import { ActivitatsComponent } from '../activitats/activitats.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
    selector: 'app-favorites',
    standalone: true,
    imports: [
        HeaderComponent,
        ActivitatsComponent,
        InfiniteScrollDirective
    ],
    templateUrl: './favorites.component.html',
    styleUrl: './favorites.component.scss'
})
export class FavoritesComponent implements OnInit {
    protected activitats: Activitat[] = [];
    public start: number = 0;
    public end: number = 20;
    public limit: number = 20;

    constructor(
        private _db: DbService,
        private _route: ActivatedRoute
    ) {

    }

    async ngOnInit() {
        await this.refresh();
    }

    public async refresh() {
        try {
            const activities = await this._db.getAllActivitats(this.start, this.end);
            for (const activitat of activities) {
                if(activitat.like) {
                    const exist = this.activitats.findIndex(a => a.id === activitat.id) !== -1;
                    if(!exist)
                        this.activitats.push(activitat);
                }
            }
        } catch (error) {
            console.error('Error fetching categories or activities:', error);
        }
    }

    async onScrollDown() {
        this.start = this.activitats.length - 1;
        this.end = this.activitats.length + this.limit;
        await this.refresh();
    }

    onScrollUp() {
        console.log("scrolled up!!");
    }
}
