import { Injectable } from '@angular/core';
import { Activitat } from '../models/Activitat';
import { IDBPDatabase, openDB } from 'idb';
import { Category } from '../models/Category';
import { Db } from '../models/Db';
import { ActivitatJson } from '../models/ActivitatJson';
import { CategoryJson } from '../models/CategoryJson';
import { Ubicacio } from '../models/Ubicacio';

@Injectable({
    providedIn: 'root'
})
export class DbService {
    private dbPromise: Promise<IDBPDatabase<Db>>;

    constructor() {
        this.dbPromise = openDB<Db>('activitats', 1, {
            upgrade(db) {
                let activitatsStore = db.createObjectStore('activitats', {keyPath: 'id'});
                activitatsStore.createIndex('categoryIndex', 'categoryIndex', {unique: false, multiEntry: true});
                activitatsStore.createIndex("startIndex", "start", {unique: false});
                activitatsStore.createIndex("likeIndex", "like", {unique: false});
                let categoriesStore = db.createObjectStore('categorias', {keyPath: 'id'});
                categoriesStore.createIndex("featureIndex", "feature", {unique: false});
                db.createObjectStore('ubicacions', {keyPath: 'id'});
            }
        });
    }

    async syncActivitats(activitatsFromJson: ActivitatJson[], categoryFromJson: CategoryJson[]): Promise<void> {
        const db = await this.dbPromise;
        const currentActivitats: Activitat[] = await db.getAll('activitats');
        const jsonActivitatIds = new Set(activitatsFromJson.map((e: ActivitatJson): number => parseInt(e.id)));

        const exeitingFeatureCategories = await this.getFeatureCategories();
        const existingfeatureCategoryIds = exeitingFeatureCategories.map(c => c.id);

        for (const c of categoryFromJson) {
            const existingCategory = exeitingFeatureCategories.find((c2) => c2.id === parseInt(c.id));
            const newCategory = {
                id: parseInt(c.id),
                nom: c.titol,
                checked: existingCategory?.checked ?? true,
                feature: true,
                parent: this.getParentCategory(existingfeatureCategoryIds, parseInt(c.id))
            }
            await db.put('categorias', newCategory);
        }

        const currentfeatureCategories = await this.getFeatureCategories();
        const featureCategoryIds = currentfeatureCategories.map(c => c.id);

        const activitats: Promise<Activitat>[] = activitatsFromJson.map(async (activitatJSON): Promise<Activitat> => {

            const [dateIniciPart, timeIniciPart] = activitatJSON.datainici.split(' ');
            const [dayInici, monthInici, yearInici] = dateIniciPart.split('/');
            const dataInici = `${yearInici}-${monthInici}-${dayInici}${timeIniciPart ? 'T' + timeIniciPart : ''}`;

            const [dateFinalPart, timeFinalPart] = activitatJSON.datafinal.split(' ');
            const [dayFinal, monthFinal, yearFinal] = dateFinalPart.split('/');
            const dataFinal = `${yearFinal}-${monthFinal}-${dayFinal}T${timeFinalPart ? 'T' + timeFinalPart : ''}`;
            const categories: Promise<Category>[] = activitatJSON.categories.categoria.map(async (c: CategoryJson): Promise<Category> => {
                return {
                    id: parseInt(c.id),
                    nom: c.titol,
                    checked: true,
                    feature: categoryFromJson.findIndex((item: CategoryJson) => parseInt(item.id) === parseInt(c.id)) !== -1,
                    parent: this.getParentCategory(featureCategoryIds, parseInt(c.id))
                }
            });
            const currentCategories: Category[] = [];

            for (let category of categories) {
                currentCategories.push(await category);
            }
            if (activitatJSON.ubicacio) {
                activitatJSON.ubicacio.id = parseInt(String(activitatJSON.ubicacio.id));
                const existingUbicacio: Ubicacio | undefined = await this.getUbicacio(parseInt(String(activitatJSON.ubicacio.id)));
                if (!existingUbicacio) {
                    await this.addUbicacio(activitatJSON.ubicacio)
                }
            }

            const current: Activitat | undefined = await this.getActivitat(parseInt(activitatJSON.id));

            let like = 0;

            if (current)
                like = current.like ?? 0
            if (activitatJSON.ubicacio)
                activitatJSON.ubicacio.id = parseInt(String(activitatJSON.ubicacio.id))
            return {
                id: parseInt(activitatJSON.id),
                title: activitatJSON.titol,
                descriptionShort: activitatJSON.entradeta,
                description: activitatJSON.descripcio,
                schedule: activitatJSON.horari,
                image: activitatJSON.imatge,
                price: activitatJSON.preubase,
                venta_entrades: activitatJSON.venta_entrades,
                url: activitatJSON.urlDetall,
                ticketLink: activitatJSON.venta_entrades,
                start: activitatJSON.datainici ? new Date(dataInici).getTime() : null,
                end: activitatJSON.datafinal ? new Date(dataFinal).getTime() : null,
                like: like,
                ubicacio: activitatJSON.ubicacio,
                organitzadors: activitatJSON.mesinformacio.organitzadors,
                categories: currentCategories,
                categoryIndex: activitatJSON.categories.categoria.map((c: CategoryJson): number => {
                    return parseInt(c.id);
                })
            };
        });


        // Add or update activitats
        for (const activitat of activitats) {
            await db.put('activitats', await activitat);
            for (let categoria of (await activitat).categories) {
                await db.put('categorias', await categoria);
            }
        }





        // Delete activitats not in JSON
        for (const activitat of currentActivitats) {
            if (!jsonActivitatIds.has(activitat.id)) {
                await db.delete('activitats', activitat.id);
            }
        }
    }

    async addActivitat(activitat: Activitat): Promise<void> {
        const db = await this.dbPromise;
        await db.put('activitats', activitat);
    }

    async getActivitat(id: number): Promise<Activitat | undefined> {
        const db = await this.dbPromise;
        return await db.get('activitats', id);
    }

    async getAllActivitats(start?: number, end?: number): Promise<Activitat[]> {
        const db = await this.dbPromise;
        const now = Date.now(); // Obtener el timestamp actual

        // Si no hay paginación, devolver todas las actividades con start > now, ordenadas por start
        const index = db.transaction('activitats', 'readonly').objectStore('activitats').index('startIndex');
        const list = await index.getAll(IDBKeyRange.lowerBound(now));
        if (start !== undefined && end !== undefined)
            return list.slice(start, end);
        return list;
    }


    async getEventsByCategory(categoriaId: number, limit?: number, offset?: number): Promise<Activitat[]> {
        const db = await this.dbPromise;
        const now = Date.now(); // Obtener el timestamp actual

        if (limit !== undefined && offset !== undefined) {
            const tx = db.transaction('activitats', 'readonly');
            const store = tx.objectStore('activitats');

            // Usamos el índice de categoría
            const index = store.index('categoryIndex');
            const keyRange = IDBKeyRange.only(categoriaId); // Filtrar por categoría

            const allActivitats: Activitat[] = [];
            let cursor = await index.openCursor(keyRange, 'next'); // Cursor para la categoría específica

            // Filtrar actividades con 'start' > now
            while (cursor && (cursor.value.start ?? 0) <= now) {
                cursor = await cursor.continue();
            }

            // Saltar al offset
            let skipped = 0;
            while (cursor && skipped < offset) {
                cursor = await cursor.continue();
                skipped++;
            }

            // Añadir actividades hasta llegar al límite
            let added = 0;
            while (cursor && added < limit) {
                if ((cursor.value.start ?? 0) > now) { // Solo añadir si el start es mayor a la fecha actual
                    allActivitats.push(cursor.value);
                    added++;
                }
                cursor = await cursor.continue();
            }

            // Ordenar las actividades por el campo 'start' después de la paginación
            return allActivitats.sort((a, b) => (a.start ?? 0) - (b.start ?? 0));
        }

        // Si no hay paginación, devolver todas las actividades filtradas por categoría con start > now, ordenadas por start
        const index = db.transaction('activitats', 'readonly').objectStore('activitats').index('categoryIndex');
        const keyRange = IDBKeyRange.only(categoriaId);
        const activitats = await index.getAll(keyRange);

        // Filtrar actividades con 'start' > now y ordenarlas por 'start'
        return activitats.filter(activitat => activitat.start ?? 0 > now).sort((a, b) => (a.start ?? 0) - (b.start ?? 0));
    }

    async getLikeEvents(limit?: number, offset?: number): Promise<Activitat[]> {
        const db = await this.dbPromise;
        const now = Date.now(); // Obtener el timestamp actual

        if (limit !== undefined && offset !== undefined) {
            const tx = db.transaction('activitats', 'readonly');
            const store = tx.objectStore('activitats');

            // Usamos el índice de categoría
            const index = store.index('likeIndex');
            const keyRange = IDBKeyRange.only(1); // Filtrar por categoría

            const allActivitats: Activitat[] = [];
            let cursor = await index.openCursor(keyRange, 'next'); // Cursor para la categoría específica

            // Filtrar actividades con 'start' > now
            while (cursor && (cursor.value.start ?? 0) <= now) {
                cursor = await cursor.continue();
            }

            // Saltar al offset
            let skipped = 0;
            while (cursor && skipped < offset) {
                cursor = await cursor.continue();
                skipped++;
            }

            // Añadir actividades hasta llegar al límite
            let added = 0;
            while (cursor && added < limit) {
                if ((cursor.value.start ?? 0) > now) { // Solo añadir si el start es mayor a la fecha actual
                    allActivitats.push(cursor.value);
                    added++;
                }
                cursor = await cursor.continue();
            }

            // Ordenar las actividades por el campo 'start' después de la paginación
            return allActivitats.sort((a, b) => (a.start ?? 0) - (b.start ?? 0));
        }

        // Si no hay paginación, devolver todas las actividades filtradas por categoría con start > now, ordenadas por start
        const index = db.transaction('activitats', 'readonly').objectStore('activitats').index('likeIndex');
        const keyRange = IDBKeyRange.only(1);
        const activitats = await index.getAll(keyRange);

        // Filtrar actividades con 'start' > now y ordenarlas por 'start'
        return activitats.filter(activitat => activitat.start ?? 0 > now).sort((a, b) => (a.start ?? 0) - (b.start ?? 0));
    }


    async addCategoria(categoria: Category): Promise<void> {
        const db = await this.dbPromise;
        await db.put('categorias', categoria);
    }

    async getCategoria(id: number): Promise<Category | undefined> {
        const db = await this.dbPromise;
        return await db.get('categorias', id);
    }

    async getAllCategories(): Promise<Category[]> {
        const db = await this.dbPromise;
        return await db.getAll('categorias');
    }

    async getFeatureCategories(): Promise<Category[]> {
        const db = await this.dbPromise;
        const allCategories = await db.getAll('categorias');
        return allCategories.filter(Categoria => Categoria.feature);
    }

    async addUbicacio(ubicacio: Ubicacio): Promise<void> {
        const db = await this.dbPromise;
        await db.put('ubicacions', ubicacio);
    }

    async getUbicacio(id: number): Promise<Ubicacio | undefined> {
        const db = await this.dbPromise;
        return await db.get('ubicacions', id);
    }

    async getAllUbicacions(): Promise<Ubicacio[]> {
        const db = await this.dbPromise;
        return await db.getAll('ubicacions');
    }

    getParentCategory(featureCategories: number[], category: number): number | null {
        for (let featureCate of featureCategories) {
            if (category.toString().startsWith(featureCate.toString()) || category.toString() === featureCate.toString()) {
                return featureCate;
            }
        }
        return null; // Devuelve null si no encuentra ninguna coincidencia
    }
}
