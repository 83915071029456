<app-header  [back]="true"></app-header>

<div class="jumbotron">
    <div class="over-text">
        <div class="container-fluid">
            <h1>Activitats dessades</h1>
        </div>
    </div>
</div>

<div class="container"
     infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollUpDistance]="1.5"
     [infiniteScrollThrottle]="50"
     (scrolled)="onScrollDown()"
     (scrolledUp)="onScrollUp()">
    <app-activitats [activitats]="activitats"></app-activitats>
</div>
