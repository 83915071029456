import { Component, OnInit } from '@angular/core';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { HeaderComponent } from '../../layout/header/header.component';
import { Activitat } from '../../models/Activitat';
import { DbService } from '../../services/db.service';
import { ActivatedRoute } from '@angular/router';
import { Category } from '../../models/Category';
import { ActivitatsComponent } from '../activitats/activitats.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
    selector: 'app-category',
    standalone: true,
    imports: [
        DatePipe,
        HeaderComponent,
        NgForOf,
        NgIf,
        ActivitatsComponent,
        InfiniteScrollDirective
    ],
    templateUrl: './category.component.html',
    styleUrl: './category.component.scss'
})
export class CategoryComponent implements OnInit {
    protected activitats: Activitat[] = [];
    protected category?: Category;
    public page: number = 0;
    public limit: number = 20;

    constructor(
        private _db: DbService,
        private _route: ActivatedRoute
    ) {

    }

    async ngOnInit() {
        try {
            this._route.params.subscribe(async params => {
                this.category = await this._db.getCategoria(Number(params['id']));
            });

        } catch (error) {
            console.error('Error fetching category:', error);
        }

        await this.refresh();

    }

    public async refresh() {
        try {

            this._route.params.subscribe(async params => {
                this.activitats = this.activitats.concat(await this._db.getEventsByCategory(Number(params['id']), this.limit, this.page));
            })


        } catch (error) {
            console.error('Error fetching categories or activities:', error);
        }
    }

    async onScrollDown() {
        this.page++;
        await this.refresh();
    }

    onScrollUp() {
        console.log("scrolled up!!");
    }
}
