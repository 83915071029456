import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Json } from '../models/Json';

@Injectable({
    providedIn: 'root'
})
export class ActivitatService {
    private apiUrlInfo = environment.infoUrl;
    private apiUrl = environment.jsonUrl;
    private apiUrlCategoryInfo = environment.infoCategoryUrl
    private apiUrlCategory = environment.jsonCategoryUrl

    constructor(private http: HttpClient) {
    }

    getEvents(): Observable<Json> {
        const uniqueParam = new Date().getTime();
        return this.http.get<Json>(this.apiUrl + '?' + uniqueParam);
    }

    getCategories(): Observable<Json> {
        const uniqueParam = new Date().getTime();
        return this.http.get<Json>(this.apiUrlCategory + '?' + uniqueParam);
    }

    getInfo(): Observable<string> {
        const uniqueParam = new Date().getTime();

        return this.http.get<string>(this.apiUrlInfo + '?' + uniqueParam, {responseType: 'text' as 'json'});
    }

    getInfoCategory(): Observable<string> {
        const uniqueParam = new Date().getTime();

        return this.http.get<string>(this.apiUrlCategoryInfo + '?' + uniqueParam, {responseType: 'text' as 'json'});
    }

}
