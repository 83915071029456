import { ApplicationConfig, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideHttpClient } from '@angular/common/http';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}),
        provideRouter(routes, withInMemoryScrolling({
            scrollPositionRestoration: "top",
        })),
        provideServiceWorker('ngsw-worker.js', {
            enabled: true,//!isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }), provideHttpClient()
    ]
};
