<app-header></app-header>

<div class="wrapper">
    <div *ngIf="1 !== 1" class="container">
        <div class="row">
            <div class="col-12">
                <p-carousel
                    [value]="activitats"
                    [numVisible]="2"
                    [numScroll]="2"
                    [showNavigators]="false"
                    [circular]="true">
                    <ng-template let-activitat pTemplate="item">
                        <div class="col-12">
                            <div class="banner">
                                <div class="over-text">
                                    <h1>
                                        {{ activitat.title }}
                                    </h1>
                                </div>
                                <div class="background">
                                    <img
                                        src="{{activitat.image}}"
                                        [alt]="activitat.name"
                                        class="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </div>
    </div>

    <div class="container"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollUpDistance]="1.5"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScrollDown()"
         (scrolledUp)="onScrollUp()">


        <!-- Navigation Tabs -->
        <div class="row mt-3">
            <div class="col-12">
                <ul class="nav nav-pills">
                    <li class="nav-item w-50  text-center">
                        <a class="nav-link" [ngClass]="{active: !all}" [routerLink]="['/']">La meva agenda</a>
                    </li>
                    <li class="nav-item w-50 text-center">
                        <a class="nav-link" [ngClass]="{active: all}" [routerLink]="['/all']" href="#">Veure-ho tot</a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Card Grid -->
        <app-activitats [activitats]="activitats"></app-activitats>
    </div>

</div>
