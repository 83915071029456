
<div *ngIf="item" [routerLink]="['/activitat', item.id]" class="card">
    <img *ngIf="item.image" src="{{item.image}}" class="card-img-top" alt="Event Image">
    <img *ngIf="!item.image" src="/img/category/{{getParentCategory()}}.jpg" (error)="item.image = 'img/placeholder/activitat.jpg'" class="card-img-top" alt="Event Image">
    <div class="card-body">
        <p class="event-date d-flex justify-content-between" >
            <span *ngIf="item.start">{{item.start|date:'dd/MM/yyyy'}}</span><span *ngIf="item.end"> - {{item.end|date:'dd/MM/yyyy'}}</span>
            <i class="fa fa-heart" (click)="$event.stopPropagation(); like()" [ngClass]="{'fa-regular': !item.like, 'color-main-2': item.like}"></i>
        </p>
        <h5 class="card-title">{{item.title}}</h5>
        <p class="card-text">{{item.ubicacio.nom}}</p>
    </div>
</div>
