<app-header [back]="true"></app-header>
<div class="wrapper">

    <div class="container mt-4">
        <!-- Caja de búsqueda con botón -->
        <div class="row">
            <div class="col-12">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Buscar...">
                    <button class="btn btn-primary" type="button">
                        <i class="fas fa-search"></i> Buscar
                    </button>
                </div>
            </div>
        </div>

        <!-- Botón de filtros avanzados -->
        <div class="row mt-3">
            <div class="col-12 d-flex">
                <button class="btn btn-secondary text-white w-100" (click)="categoryOpened = !categoryOpened" type="button" data-bs-toggle="collapse" data-bs-target="#advancedFilters">
                    Cerca avançada <i class="fa-solid" [ngClass]="{'fa-caret-down': !categoryOpened, 'fa-caret-up': categoryOpened}"></i>
                </button>
            </div>
        </div>

        <!-- Opciones de filtros avanzados -->
        <div class="collapse mt-3" id="advancedFilters">
            <div class="card card-body">
                <!-- Aquí van las opciones de filtro -->
                <div class="row mt-5">
                    <div class="col-12">
                        <button (click)="selectAll(true)" class="btn btn-primary text-white">
                            <span>Tots</span>
                        </button>
                        <button (click)="selectAll(false)" class="btn btn-secondary text-white ms-2">
                            <span>Cap</span>
                        </button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div  *ngFor="let category of categories"  class="col-12 col-lg-6">
                        <div class="form-check form-switch">
                            <input class="form-check-input" (change)="check(category)" [checked]="category.checked" type="checkbox" role="switch" id="category-{{category.id}}">
                            <label class="form-check-label" for="category-{{category.nom}}">{{category.nom}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollUpDistance]="1.5"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScrollDown()"
         (scrolledUp)="onScrollUp()">
        <app-activitats [activitats]="activitats"></app-activitats>
    </div>
</div>
