import { Component, Input } from '@angular/core';
import { Navigation, RouterLink, RouterModule } from '@angular/router';
import { CommonModule, Location } from '@angular/common';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {
    @Input() shareData!: { text: string; title: string; url: string };
    @Input() back: boolean = false;

    constructor(
        private _location: Location
    ) {
    }
    async onShare() {
        await navigator.share(this.shareData)
    }

    goBack() {
        this._location.back();
    }
}
