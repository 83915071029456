import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ActivitatsComponent } from './pages/activitats/activitats.component';
import { CategoryComponent } from './pages/category/category.component';
import { SingleComponent } from './pages/single/single.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { FavoritesComponent } from './pages/favorites/favorites.component';
import { SearchComponent } from './pages/search/search.component';
import { UbicacioComponent } from './pages/ubicacio/ubicacio.component';

export const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'all', component: HomeComponent},
    {path: 'categoria/:id', component: CategoryComponent},
    {path: 'ubicacio/:id', component: UbicacioComponent},
    {path: 'activitat/:id', component: SingleComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'favorites', component: FavoritesComponent},
    {path: 'search/:query', component: SearchComponent}
];
