import { Component } from '@angular/core';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { HeaderComponent } from '../../layout/header/header.component';
import { Activitat } from '../../models/Activitat';
import { DbService } from '../../services/db.service';
import { ActivatedRoute } from '@angular/router';
import { Ubicacio } from '../../models/Ubicacio';

@Component({
  selector: 'app-ubicacio',
  standalone: true,
    imports: [
        DatePipe,
        HeaderComponent,
        NgForOf,
        NgIf
    ],
  templateUrl: './ubicacio.component.html',
  styleUrl: './ubicacio.component.scss'
})
export class UbicacioComponent {
    protected item?: Ubicacio;

    constructor(
        private _db: DbService,
        private _route: ActivatedRoute
    ) {

    }

    ngOnInit() {
        this._route.params.subscribe(async params => {
            this.item = await this._db.getUbicacio(Number(params['id']))
        })

    }
}
