import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DbService } from '../../services/db.service';
import { Activitat } from '../../models/Activitat';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { HeaderComponent } from '../../layout/header/header.component';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { ActivitatComponent } from '../../components/activitat/activitat.component';

@Component({
    selector: 'app-single',
    standalone: true,
    imports: [
        HeaderComponent,
        NgForOf,
        NgIf,
        DatePipe,
        RouterLink,
        ActivitatComponent
    ],
    templateUrl: './single.component.html',
    styleUrl: './single.component.scss'
})
export class SingleComponent implements OnInit, AfterViewInit {
    protected item!: Activitat;
    public activitats: Activitat[] = [];

    constructor(
        private _db: DbService,
        private _route: ActivatedRoute
    ) {

    }
    ngAfterViewInit() {
        this.activitats = [];
    }

    ngOnInit() {

        this._route.params.subscribe(async params => {
            const item = await this._db.getActivitat(Number(params['id']));
            if(item)
                this.item = item;
            this.activitats = [];
            for(let category of this.item?.categories ?? []){
                const response = await this._db.getEventsByCategory(category.id, 50, 0);
                for(let item of response) {
                    const found = this.activitats.filter((a: Activitat) => a.title === item.title && this.item?.title !== item.title).length !== 0
                    if (!found) {
                        this.activitats.push(item);
                        if(this.activitats.length === 2)
                            break;
                    }
                }
                if(this.activitats.length === 2)
                    break;


            }
            this.activitats = this.activitats.sort((a: Activitat, b: Activitat) => (a.start ?? 0) > (b.start ?? 0) ? 1 : -1);

        });



    }

    getParentCategory() {
        return this.item.categories.find((c) => c.parent !== null)?.parent;
    }

}
