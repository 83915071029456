<header class="main-header">

    <div class="container-fluid pt-2">
        <div class="row">
            <!-- Logo -->
            <div class="col-4 col-md-2">
                <div *ngIf="!back" class="logo" [routerLink]="['/']">
                    <img src="/img/logo-vertical-white.png" alt="Logo">
                </div>
                <i *ngIf="back" (click)="goBack()" class="back-button fa-solid fa-angle-left"></i>
            </div>
            <!-- Title and Icons -->
            <div class="col-8 col-md-10">
                <div class="header-content d-flex justify-content-end">
                    <div *ngIf="!shareData" class="header-icons ms-4">
                        <a href="#" class="header-icon"  [routerLink]="['/profile']">
                            <i class="fa-regular fa-user"></i>
                        </a>
                        <a href="#" class="header-icon"  [routerLink]="['/favorites']">
                            <i class="fa-regular fa-heart"></i>
                        </a>
                        <a href="#" class="header-icon"  [routerLink]="['/search', 'all']">
                            <i class="fa fa-search"></i>
                        </a>
                    </div>
                    <div *ngIf="shareData" class="header-icons ms-4 mt-1">
                        <i class="fa-regular fa-share-from-square header-icon" (click)="onShare()"></i>
                    </div>
                </div>
            </div>
        </div>

    </div>
</header>
