import { Component, Input } from '@angular/core';
import { DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Activitat } from '../../models/Activitat';
import { RouterLink } from '@angular/router';
import { DbService } from '../../services/db.service';

@Component({
  selector: 'app-activitat',
  standalone: true,
    imports: [
        DatePipe,
        NgForOf,
        NgIf,
        RouterLink,
        NgClass
    ],
  templateUrl: './activitat.component.html',
  styleUrl: './activitat.component.scss'
})
export class ActivitatComponent {
    @Input() item!: Activitat;

    constructor(
        private _db: DbService,
    ) {
    }

    async like() {
        if (this.item) {
            this.item.like = this.item.like ? 0 : 1;
            await this._db.addActivitat(this.item);
        }
    }

    getParentCategory() {
        return this.item.categories.find((c) => c.parent !== null)?.parent;
    }
}
