<app-header [back]="true"
            [shareData]="{title: item.title, text: item.descriptionShort, url: item.url}"></app-header>

<div class="jumbotron">

    <img *ngIf="item.image" src="{{item.image}}" class="card-img-top" alt="Event Image">
    <img *ngIf="!item.image" src="/img/category/{{getParentCategory()}}.jpg" (error)="item.image = 'img/placeholder/activitat.jpg'" class="card-img-top" alt="Event Image">

    <div class="over-text">
        <div class="container-fluid">
            <h1>{{ item.title }}</h1>
            <div class="event-details">
                <p>{{ item.start|date:'dd/MM/yyyy' }} a les {{ item.start|date:'HH:mm' }}h</p>
                <p *ngIf="item.ubicacio && item.ubicacio.nom">
                    <a class="text-reset" [routerLink]="['/ubicacio', item.ubicacio.id]">{{ item.ubicacio.nom }} <i class="fa fa-location-dot"></i></a>
                </p>
                <p>
                    <span *ngFor="let organitzador of item.organitzadors; let i = index;"><span
                        *ngIf="i > 0">, </span>{{ organitzador.nom }}</span>
                </p>
            </div>

            <div class="event-categories mt-3">
                <span class="badge"
                      *ngFor="let category of item.categories"
                      [routerLink]="['/categoria', category.id]">{{ category.nom }}</span>
            </div>
            <div (click)="single_content.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})" class="scroll-down">
                <i  #single_content class="fa-solid fa-angle-down"></i>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">

    <!-- Contenido del evento -->
    <div  class="event-body container">
        <h1 class="mt-3">{{ item.title }}</h1>
        <h2>{{ item.descriptionShort }}</h2>
        <p [innerHTML]="item.description"></p>

        <img *ngIf="item.image" src="{{item.image}}" class="w-100" alt="Event Image">
        <img *ngIf="!item.image" src="/img/category/{{getParentCategory()}}.jpg" (error)="item.image = 'img/placeholder/activitat.jpg'" class="w-100" alt="Event Image">

        <div class="event-details">
            <p>{{ item.start|date:'dd/MM/yyyy' }} a les {{ item.start|date:'HH:mm' }}h</p>
            <p *ngIf="item.ubicacio && item.ubicacio.nom">
                <a class="text-reset" [routerLink]="['/ubicacio', item.ubicacio.id]"><i class="fa fa-location-dot"></i> {{ item.ubicacio.nom }}</a>
            </p>
            <p>
                    <span *ngFor="let organitzador of item.organitzadors; let i = index;"><span
                        *ngIf="i > 0">, </span>{{ organitzador.nom }}</span>
            </p>
        </div>

        <span *ngIf="item.schedule"><strong>Horari:</strong> {{ item.schedule }}</span><br>
        <span *ngIf="item.price"><strong>Preu:</strong> {{ item.price }}€</span>

        <a *ngIf="item.venta_entrades" target="_blank" [href]="item.venta_entrades" class="btn btn-primary w-100 mt-3">
            Entrades
        </a>
        <div class="event-categories mt-3">
                <span class="badge"
                      *ngFor="let category of item.categories"
                      [routerLink]="['/categoria', category.id]">{{ category.nom }}</span>
        </div>



    </div>
    <hr>
    <h3>Activitats relacionades</h3>

    <div  *ngIf="activitats.length > 0"  class="row row-cols-2 g-3 mt-3">
        <app-activitat class="col" *ngFor="let activitat of activitats" [item]="activitat"></app-activitat>
    </div>

</div>
