<app-header [back]="true" [shareData]="{title: item?.nom ?? '', text: item?.adreca ?? '', url: item?.imatge ?? ''}"></app-header>

<div class="jumbotron">

    <img *ngIf="item?.imatge" src="{{item?.imatge}}" class="card-img-top" alt="Event Image">
    <img *ngIf="!item?.imatge" src="/img/placeholder/activitat.jpg" class="card-img-top" alt="Event Image">
    <div class="over-text">
        <div class="container-fluid">
          <div class="row">
              <div class="col-12">
                  <h1>{{ item?.nom }}</h1>
                  <p>
                      <b>{{item?.adreca}}</b><br>
                      {{item?.municipi}}<br>
                      {{item?.codipostal}} {{item?.ciutat}}<br><br>
                      <a class="text-reset" href="https://www.google.com/maps/search/?api=1&query={{item?.puntGeo?.lat}}%2C{{item?.puntGeo?.lon}}">Com arribar-hi <i class="fa fa-location-dot"></i></a>
                  </p>

              </div>
          </div>
        </div>
    </div>
</div>
